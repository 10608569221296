@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base scrollbar styles */
.scroll-container::-webkit-scrollbar {
  width: 8px;
}

/* Hide scrollbar by default */
.scroll-container::-webkit-scrollbar-track {
  background-color: transparent; 
  border-radius: 10px;
}

/* Hide scrollbar thumb by default */
.scroll-container::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 10px;
  transition: background-color 0.3s ease; /* Add transition for smooth effect */
}

/* Show scrollbar on hover or focus */
.scroll-container:hover::-webkit-scrollbar-thumb,
.scroll-container:focus::-webkit-scrollbar-thumb {
  background-color: #555;
}

body {
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3 {
  font-family: 'Montserrat', sans-serif;
}

button {
  font-family: 'Roboto', sans-serif;
}

  
.textarea-placeholder {
  display: flex;
  align-items: center;
  height: 100%;
}

.textarea-placeholder::placeholder {
  display: flex;
  align-items: center;
  height: 100%;
}

